body {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: 'Public Sans', sans-serif;
}

main>div {
  height: 10rem;
  margin: 2rem;
  border: darkgrey 1px solid;
  border-radius: 8px;
  overflow: hidden;
}

h1 {
  font-size: 2rem;
  font-weight:600;
}

