@charset "utf-8";

// Import partials from `_sass`
@import "fonts";
@import "main";

// Core brand colors
$sage: #9fcfca;
$aqua: #5cb8b2;
$forest: #00665e;
$daffodil: #daff0a;
$ocean: #3d7cca;
$peach: #EAEA6C;
$brick: #030303;
$stone: #cdb4a7;
$sky: #a2c5d3;
$squash: #BB6125;
$squash-hover: #c76b2d;

// Color classes
.bg-forest { background: $forest; }
.bg-aqua { background: $aqua; }
.text-forest { color: $forest;}

// Our variables
$base-font-family: 'Public Sans', sans-serif;
$base-font-size: 16px;
$base-font-weight: 300;
$small-font-size: $base-font-size * 0.875;
$base-line-height: 1.5;
$content-line-height: 1.75;
$header-font: $base-font-family;
// $monospace: 'JetBrains Mono', monospace;

$spacing-unit: 2rem;
$pad: 3rem;

$text-color: #000;
$background-color: #ffffff;
$brand-color: $forest;
$eggplant: $brick;
$eggplant-dark-t: $brick;

$ll: $brick;
$link-color: $ocean;
$subtle-link-color: $ocean;

$code: #e9e9e9;

$land: #C0DF88; // #faede1 -- this is the map's land color
$land-dark: $stone;
$frame: $land-dark;


$grey-color: #d8d6df;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark: darken($grey-color, 25%);




// Elements - General
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  font-weight: $base-font-weight;
  line-height: $base-line-height; // this seems to vbe overridden by
}

// Buttons
.btn:focus {
  box-shadow: none;
}

.btn-round {
  border-radius: 2rem;
}

// Tables
tr.premium td {
  background-color: #d6e4bc;
}

// --- CTA-category
.cta-category-container {
  .h2-cta {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    
  }
  .cta-category-link {
    color: inherit !important;
  }
  
}
.map-card {
  .card-img-top {
    width: 100%;
    object-fit: contain;
    padding: 1rem;
  }
  h2, p {
    transition: ease-in-out .5s;
    color: black;
    border-bottom: none !important; // TODO temporary to overwrite content-wrapper styling
  }
  &:hover, &:focus {
    h2 {
      transition: ease-in-out .5s;
      color: $squash;
    }
  }
}


// --- Headers ---
.header-docs-wrapper {
  max-width: 1440px;
}
// Navbar
.navbar-brand {
  font-size: x-large;
}

.current-nav {
  color: $daffodil !important;
  text-decoration: underline;
}

.btn-login {
  background: $squash;
  color: white;
  transition: ease-in-out .5s;

  &:hover {
    color: white;
    transform: scale(1.05);
    transition: ease-in-out .5s;
    background: $squash-hover;
  }
}


// --- Search & Breadcrumb ---
.search-breadcrumb-wrapper {
  max-width: 1440px;
}
.breadcrumb-item {
  text-transform: capitalize;
}
.breadcrumb-api { // ensures 'Api' is converted to 'API'
  text-transform: uppercase !important;
}

.breadcrumb-caret::before {
  width: 1.25em;
  // TODO centered using line height or padding, should be a better way. 
  padding-top: 0.1rem;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e") !important;
}

.breadcrumb-search {
  width: 305px;
  // TODO get accurate relative width or switch to row/col 
}

.has-search {
  
  .form-control {
  padding-left: 2.375rem;
  padding-right: 5rem;
  }
}
.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

// --- Search Modal ---
#search-modal {
  .modal-content {
    height: 80vh;
    
    .modal-header .has-search{
      width: 100%;
      padding-right: 1.5rem;
      padding-left: 3rem;
    }
    .modal-body {
      transition: ease-in-out .2s;
      .search-total {
        color: rgb(80, 80, 80);
        padding: .5rem 0;
        margin: 0 3rem;
        h4 {
          padding: 0.5rem 0;
          width: 100%;
          display: inline-flex;
          align-items: center;
        }
        span { 
          font-size: 0.6em; 
          margin: 0 1rem;
          background-color: $squash !important;
        }
      }
      
      .search-cta {
        display: none;
        color: #444;
      }
      .search-results {
        // padding: 0 2rem;
        margin: 0 3rem;
        & ul li{
          border-radius: 6px;
          padding: .5rem 1rem;
          margin: 0 1rem;
          display: flex;
          align-items: center;
          transition: ease-in-out .2s;
          p { margin-bottom: 0.2rem; }
          &:before {
            content: "\2B24";
            font-size: .4rem;
            color: rgb(60, 60, 60);
            margin-right: 1.5rem;
          }
          // TODO: Keyboard nav and focus
          // #first-result:focus {
          //   transition: ease-in-out .2s;
          //   background-color: rgb(234, 234, 234);
          //   .search-result-title { color: $squash; }
          // }
          &:hover, &:focus {
            transition: ease-in-out .2s;
            background-color: rgb(234, 234, 234);
            .search-result-title { color: $squash; }
          }
          .search-result-title { 
            font-size: 1.1rem;
            font-weight: bold;
            color: rgb(60, 60, 60);
          }
        }
      }
    }
  }
}


// --- Cards --- 
.card {
  transition: ease-in-out .5s;
  &:hover {
  transform: scale(1.01);
  transition: ease-in-out .5s;
  cursor: pointer;
  }
  &.card-link {
    text-decoration: none;
    color: inherit;
  }
}

// to force layout to align
.card-img-top {
  width: 80%;
  object-fit: fill;
  padding: 1rem;
}

.home-card {
  span { transition: ease-in-out .25s; }
  .card-title { 
    padding-top: 1rem;
    font-size: 1.5rem;
    color: black;
  } 
  .card-title-lg { 
    padding-top: 1rem;
    font-size: 2rem;
    color: black;
  } 
  &:hover {
    transition: ease-in-out .25s;  
    span {
      transition: ease-in-out .25s;
      color: $squash;
    }
  }
  .card-text {
    font-size: 1rem;
  }
  .card-text-lg {
    font-size: 1.2rem;
  }
}

.category-card {
  justify-content: center;

  .category-img {
    transition: ease-in-out .25s;
    opacity: 0.4;
  }
  .card-title {
    transition: ease-in-out .25s;
    font-size: 1.6rem;
    color: black;
    border-bottom: none !important; // TODO temporary to overwrite content-wrapper styling
  }
  &:hover {
    transition: ease-in-out .25s;
    .category-img { opacity: 0.5; }
    .card-title { color: $squash; }
  }
}

.learn-more {
  text-decoration: none;
  color: black;
  padding: 1rem;
  &:hover {
    color: $squash;
  }
  svg {
    margin-right: .5rem;
    margin-bottom: .25rem;
    // TODO better x alignment to text needed
  }
}

.row-lg {
  height: 50vh;
}

// Card Asym 
.card-img-left {
  height: 100%;
  padding: 1rem;
}
.card-img-top-2 {
  width: 60%;
  object-fit: fill;
  padding: 1rem;
}


// --- FAQs Accordion ---

.accordion-button {
  font-size: inherit;
  &:not(.collapsed) {
    color: inherit;
    background: #DAE1E9;
    &::after {
      filter: brightness(0%);
    }
  }
  &:focus {
    box-shadow: inherit;
  }
}
.accordion {
  .banner-background {
    align-items: center;
    justify-content: center;
    background: $sage;
    padding: 1rem;
    margin: 1rem 0;
    border-radius: 12px;
  }
}

.accordion-item {
  .accordion-img {
    object-fit: contain;
  }
  .card {
    transition: none;
    &:hover {
      transform: none;
      transition: none;
      cursor: inherit;
    }
  }
}

// --- Sidebar ---
#sidenav {
  overflow-y: auto;
}

.sidebar-header {
  h4 {
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: bold;
  }
}

.sidebar-faq-link {
  a {
    padding: .5rem;
    padding-inline: 1rem;
  }
  font-size: 1rem;
  font-weight: bold;
  background-color: $daffodil;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: .5rem;
}

// Toggle caret and behavior
.btn-toggle {
  display: inline-flex;
  align-items: center;
  padding: .5rem 1rem;
  padding-left: 0;
  font-weight: 600;
  color: rgba(0, 0, 0, .65);
  background-color: transparent;
  box-shadow: none;
}
.btn-toggle :focus {
  box-shadow: none !important;
}
.btn-toggle::before {
  width: 1.25em;
  line-height: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform .35s ease;
  transform-origin: .5em 50%;
}
.btn-toggle[aria-expanded="true"] {
  color: black
}
.btn-toggle[aria-expanded="true"]::before {
  transform: rotate(90deg);
}
.btn-toggle-nav a {
  display: flex;
  padding: .1875rem .5rem;
  margin-top: .125rem;
  margin-left: 1.5rem;
  text-decoration: none;
}
.btn-toggle-nav :hover,
.btn-toggle-nav :focus {
  background-color: #eee;
}
.section-list {
  .active {
    .section-link {
      color: $squash;
      cursor: default;
    }
  }
  .section-title {
    .section-link {
      &:hover {
        color: $squash;
      }
    }
  }
}

.explore-ul {
  .explore-li {
    a { padding-left: 1rem; }
    &:hover, &:focus {
      color: $squash;
    }
    i { color: rgba(0, 0, 0, .45); }
  }
}


// --- Table of Contents ---
// defaults to display h2 through h4, set in toc.html
.toc-wrapper {
  max-height: 100vh;
  padding-bottom: 1rem;
  &:last-child { // selects first ul only
    &:last-child {padding-bottom: 8rem;}
    // selects last li only, padding to get above buttons 
  }
  .toc-header {
    padding-bottom: .5rem;
    h5 {
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: bold;
    }
  }
  ul {
    display: block;
    max-height: 100%;
    overflow-y: auto;
    list-style: none;
    padding-left: 1.5rem;
    
    .toc-item {
      ul { 
        height: fit-content;
        overflow-y: visible; 
        li {font-size: smaller;}
      }
      display: block;
      padding-top: .3rem;
      padding-bottom: .3rem;
      a { display: block;}
    }
    li>a:hover, li>a:focus {
      background-color: #eee;
    }
  }
}

// --- Footer ---
footer {
  background: $aqua;
  .contact {
    img { 
      width: auto;
      max-width: 225px;
    }
    .ft-contact-link { 
      font-size: 1.1rem;
      font-weight: 700;
    }
  }
  a {
    text-decoration: none;
    color: white;
    &:hover, 
    &:focus {
      text-decoration: underline;
    }
  }
  .ft-social-icons {
    max-width: 225px;
    li > a {
      padding-left: 0;
    }
  }
  #newsletter-form {
    color: white;
    max-width: 225px;
    #newsletter-submit {
      background: $forest;
      border: none;
    }
  }
  .widget-links {
    width: fit-content;
    min-width: 250px; // to align with other widgets
    h3.widget-title {
      font-size: 22px;
      font-weight: 700; 
      width: fit-content; 
      margin-bottom: 20px;
    }
    ul { width: fit-content; }
    li {
      padding: 0.25rem 1rem;
      width: fit-content;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
    }
    li a {
      font-size: 18px;
    }
  }
}

// --- Section Overview Page ---
.overview-ul {
  .overview-li::marker {
    color: #2980b9;
  }
}

// --- API Landing Page ---
.signup-cta-row {
  padding-inline: 6rem;
  padding-top: 3rem;
  padding-bottom: 6rem;
  img {
    height: 30vh;
  }
  button {
    max-width: 80%;
  }
}

.api-row {
  transition: ease-in-out .5s;
  // text-order-right/left implies the order of the image and picture
  // this is controlled by bootstrap with order-md-1/2
  padding-top: 6rem;
  padding-bottom: 6rem;
  .text-order-right {
    padding-right: 6rem;
  }
  .text-order-left {
    padding-left: 6rem;
  }

  img {
    height: 30vh;
  }
  .learn-more {
    i { color: $squash; }
    span {
      color: black ;
      font-size: 1.2rem;
    }
  }

  &:hover, &:focus {
    transform: scale(1.02);
    transition: ease-in-out .5s;
    cursor: pointer;
    .learn-more {
      span { color: $squash }
    }
  }
}

// --- API Interactive Landing Page ---
.api-test-cta-top {}
.api-test-table {
  .table :not(:first-child) { border-top: none;} 
  font-family: $header-font;
  // font-size: large;
  font-weight: 400;
  thead {
    font-size: large;
    background: $forest;
    color: white;
    th { 
      font-weight: 500;
      padding-inline: 1.5rem; 
    }
  }
  .api-td-link {
    color: $forest !important;
    padding: 1.2rem;
    padding-left: 2rem;
    font-weight: bold;
    border-right: 1px solid #ddd;
    border-color: inherit;
  }
  .api-td-content {
    padding: 1.5rem;
    ul { padding-left: 1rem; }
  }
}
.api-test-cta-bottom {
  img {
    width: 40%;
  }
}

// --- Back to Top Arrow ---
#btn-back-to-top {
  position: sticky;
  bottom: 90px;
  right: 20px;
  height: 60px;
  width: 60px;
  display: none;
  color: $squash;
  &:hover, &:focus {
    background: $squash;
    color: white;
  }
  margin-bottom: 20px;
}

// TODO faq-spacer using <br> to push footer down. Get a better way.

// Utility
.hidden {
  display: none !important;
}
.show {
  display: block
}

.form-control:focus {
  border-color: #DAE1E9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 8px rgba(189,
    195,
    201, 0.9);
}

a {
  text-decoration: none;
  color: inherit;
  &:hover {
    color: inherit;
  }
}
.link-unstyled {
  text-decoration: none;
  color: inherit;
  &:hover {
    color: inherit;
  }
}

// -- GRID SECTION POSITIONING --
main {
  min-height: 100vh;
  max-width: 1440px;
}

#sidenav-wrapper {
  position: sticky;
  top: 0px;
  height: 100vh;
  border-right: 1px solid rgb(225, 225, 225) ;

}
#content-wrapper {
  height: calc(100% - 182px);
}
#toc-wrapper {
  position: sticky;
  top: 0px;
  height: 100vh;
  border-left: 1px solid rgb(225, 225, 225);
}
#faq-wrapper {
  max-width: 1440px;
}
.container-fluid {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
  // Bootstrap 5 defaults to .75rem
}


// --- Content (Article) ---
// applies styling to markdown/kramdown contents only
#content-wrapper {
  p, pre, li {
    line-height: $content-line-height;
  }
  
  p img {
    margin: 0 auto !important; // center images within articles
    display: block !important;
    &.with-caption {
      margin-bottom: .5rem;
    }
  }
  .img-sm {
    width: 50%;
  }
  .img-md {
    width: 75%;
  }
  .img-lg {
    width: 100%;
  }
  // captions for images
  figcaption {
    font-style: italic;
    font-size: 0.8rem;
    margin: .25rem 0 1rem 0;
  }
}


// --- Content Theme ---
// Source: https://github.com/markdowncss/modest
.content-wrapper, .accordion-body {
  padding-bottom: 2rem;  // align with sidebar and toc

  @media print {

    *,
    *:before,
    *:after {
      background: transparent !important;
      color: #000 !important;
      box-shadow: none !important;
      text-shadow: none !important;
    }

    a,
    a:visited {
      text-decoration: underline;
    }

    a[href]:after {
      content: " (" attr(href) ")";
    }

    abbr[title]:after {
      content: " (" attr(title) ")";
    }

    a[href^="#"]:after,
    a[href^="javascript:"]:after {
      content: "";
    }

    pre,
    blockquote {
      border: 1px solid $sage;
      page-break-inside: avoid;
    }

    thead {
      display: table-header-group;
    }

    tr,
    img {
      page-break-inside: avoid;
    }

    img {
      max-width: 100% !important;
    }

    p,
    h2,
    h3 {
      orphans: 3;
      widows: 3;
    }

    h2,
    h3 {
      page-break-after: avoid;
    }
  }

  pre,
  code {
    font-family: Menlo, Monaco, "Courier New", monospace;
  }

  pre {
    padding: .5rem;
    line-height: 1.25;
    overflow-x: scroll;
  }

  a,
  a:visited {
    color: #3498db;
  }

  a:hover,
  a:focus,
  a:active {
    color: #2980b9;
  }

  html {
    font-size: 12px;
  }

  @media screen and (min-width: 32rem) and (max-width: 48rem) {
    html {
      font-size: 15px;
    }
  }

  @media screen and (min-width: 48rem) {
    html {
      font-size: 16px;
    }
  }

  p,
  .modest-p {
    font-size: 1rem;
    margin-bottom: 1.3rem;
  }

  h1,
  .modest-h1,
  h2,
  .modest-h2,
  h3,
  .modest-h3,
  h4,
  .modest-h4 {
    margin: 1.414rem 0 .5rem;
    font-weight: inherit;
    line-height: 1.42;
  }

  h1,
  .modest-h1 {
    margin-top: 0;
    font-size: 2.2rem;
  }

  h2,
  .modest-h2 {
    font-size: 1.8rem;
  }

  h3,
  .modest-h3 {
    font-size: 1.6rem;
  }

  h4,
  .modest-h4 {
    font-size: 1.4rem;
  }

  h5,
  .modest-h5 {
    font-size: 1.2rem;
  }

  h6,
  .modest-h6 {
    font-size: .88rem;
  }

  small,
  .modest-small {
    font-size: .707em;
  }

  /* https://github.com/mrmrs/fluidity */

  img,
  canvas,
  iframe,
  video,
  svg,
  select,
  textarea {
    max-width: 100%;
  }

  .iframe-container {
    text-align: center;
    width: 100%;
  }

  @import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,200;0,400;0,700;1,200;1,400;1,700&display=swap');

  html {
    font-size: 18px;
    max-width: 100%;
  }

  body {
    color: #444;
    font-family: $base-font-family;
    font-weight: 200;
    margin: 0 auto;
    max-width: 48rem;
    line-height: 1.45;
    padding: .25rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $base-font-family;
  }

  h1,
  h2,
  h3 {
    border-bottom: 2px solid #fafafa;
    margin-bottom: 1.15rem;
    padding-bottom: .5rem;
  }

  blockquote {
    border-left: 8px solid $sage;
    background-color: #eeeeee;
    padding: 1rem;
    p { margin: 0; }
  }

  pre,
  code {
    background-color: #e9e9e9;
    padding: .2rem .4rem;
    border-radius: 6px;
    background-color: $code;
  }


}


// --- BOOTSTRAP CUSTOM ---
@media (max-width: 768px) {
  // centers block content with margin only on small devices
  // doesn't load from bootstrap 5 _utilities.scss, though .mx-lg-auto works
  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

// --- MOBILE ---
@media (max-width: 768px) {

  // --- Article Content ---
  #content-wrapper {
    border: none !important; // bs5 overwrite border
    .content-wide {
      max-width: 100%;
      overflow-x: auto;
      padding-top: 0 !important;
    }
    a {
      word-wrap: break-word !important;
      word-break: break-word !important;
    }
  }

  // --- Sidebar ---
  #sidenav-wrapper {
    position: inherit;
    border: none !important; // bs5 overwrite border
    height: 100%;
    padding-bottom: 0 !important;
  }
  #sidenav {
    width: 100%;

    #navbarSupportedContent {
      flex-direction: column;
      padding: 2rem; // aligns under nav toggle
      background: #eee;
      border-radius: 16px;
    }
    .sidebar-dropdown {
      width: 100%;
      &:focus {
        box-shadow: none !important
      }
    }
    .sidebar-dropdown-btn {
      width: 100%;
      span { 
        // cut off text within button
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        vertical-align: middle;
        max-width: 80%;
      }
    }
  }

  // --- Main Header  ---
  .container-lg {
    max-width: 100% !important;
    padding-left: 0px;
    padding-right: 0px;
    // Overwrite in header_lg.html to allow full width on mobile
  }
  .navbar-brand {
    font-size: 1.4rem; // keeps on one line
    display: flex;
  }
  .search-toggle-btn {
    padding: .25rem .75rem;
    box-shadow: none !important;
  }
  .main-nav-toggle {
    box-shadow: none !important;
  }

  // --- Search Modal ---
  #search-modal .modal-content {
    height: 80vh;
    .has-search { padding-left: 0 !important; }
    .has-search .form-control{ padding-right: inherit; }
    .modal-body {
      .search-total {
        padding-left: 0 !important;
        margin: 0
      }
      .search-results {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
        .search-result-li {
          padding-left: 0 !important;
          margin-left: 0 !important;
        }
      }
    }
  }

  // --- Home ---
  .home-card {
    .card-body { 
      text-align: center;
    }
  }
  .row-lg {
    height: 100%;
    .card-title-lg { font-size: 1.5rem; }
    .card-text-lg { font-size: 1rem; }
  }

  // --- FAQs ---
  .banner-background { align-items: start !important;}

  // --- Category ---
  .cta-category-container {
    .category-cta-img { width: 100%;}
    .h2-cta {text-align: center;}
    .cta-category-link { color: inherit !important; }
  }

  // --- Footer ---
  .footer-content {
    width: 100%;
    justify-content: space-between;
  }
}

// --- MEDIUM MOBILE ---
@media (max-width: 500px) {
  .api-test-table tbody tr{
    display: inline-flex;
    flex-direction: column;
    padding: 0rem 0;
    width: 100%;
    td {
      border: none !important;
      width: 100%;
    }
    .api-td-link {
      padding: 1rem 1.5rem .5rem !important;
    }
    .api-td-content {
      padding: 0 1.5rem 1rem;
      border-bottom: #ddd;
    }
  }
  .api-test-cta-bottom {
    img {
      width: 80%;
    }
  }
}


// --- SMALL MOBILE ---
// iPhone SE, others
@media (max-width: 375px) {
  // --- Main Header  ---
  .navbar-brand {
    font-size: 1.2rem; // keeps on one line
    display: flex;
  }
    
}
